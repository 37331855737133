import React, { useEffect, useState } from "react";
import AppButton from "components/AppButton";
import { getBusinessConnectionStatus } from "pages/FbManagerAccess/functions";
import "./styles.css";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import AppText from "components/AppText";
import { formatMessageInPlaceholder } from "functions/global";

const InstructionStep = (props: any) => {
  const {
    selectedFbAccount,
    adminDetails,
    fbAccessToken,
    connectId,
    selectedFbPage,
    setActiveStep,
    setConfirmAccess,
  } = props;

  const [copied, setCopied] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyTextToClipboard = () => {
    navigator.clipboard
      .writeText(adminDetails?.businessManagerId || "")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const checkStatus = async () => {
    try {
      setLoading(true);
      const status = await getBusinessConnectionStatus({
        accessToken: fbAccessToken,
        adAccountId: selectedFbAccount?.id,
        pageId: selectedFbPage?.id || "",
        adminDbId: connectId,
      });

      setStatus(status);
    } catch (error) {
      setStatus(false);
      console.error("Error occurred while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const goToConnectedPage = () => {
    setConfirmAccess(true);
    setActiveStep(2);
  };

  return (
    <div>
      <p>
        <AppText>You're almost done!</AppText>
      </p>
      <p>
        <AppText
          values={{
            selectedAccount: selectedFbAccount?.name,
            businessName: selectedFbAccount?.business?.name,
          }}
        >
          {`To grant access to {selectedAccount}'s Ad Account '{businessName}',
          follow these simple instructions:`}
        </AppText>
      </p>

      <div>
        <div className="instruction-step-div">
          <p>
            <AppText>1. Copy your partner's Business ID:</AppText>{" "}
          </p>
          <div className="instruction-business-id-copy-div">
            <span>{adminDetails?.businessManagerId}</span>
            {copied ? (
              formatMessageInPlaceholder("Copied!")
            ) : (
              <FontAwesomeIcon
                fontSize={20}
                icon={faCopy}
                className="instruction-business-id-copy-icon"
                onClick={copyTextToClipboard}
              />
            )}
          </div>
        </div>

        <div className="instruction-step-div">
          <p>
            <AppText>2. Open your business Manager:</AppText>{" "}
          </p>
          <AppButton
            onClick={() =>
              window.open(
                `https://business.facebook.com/settings/partners/?business_id=${selectedFbAccount?.business?.id}`
              )
            }
            className="fb-manager-access-btn"
            title="OPEN BUSINESS MANAGER"
          />
        </div>

        <div className="instruction-step-div">
          <p>
            <AppText
              values={{
                add: <b>"Add"</b>,
                shareAssets: <b>"Partner to share assets with"</b>,
              }}
            >
              {`3. Click on the blue dropdown {add} under {shareAssets}`}
            </AppText>
          </p>
          <img
            alt="instruction-step"
            src="images/fb-ad-connector/step_3.png"
            className="instruction-step-div-img"
          />
        </div>

        <div className="instruction-step-div">
          <p>
            <AppText>4. Paste in the copied Business Manager ID</AppText>
          </p>
        </div>

        <div className="instruction-step-div">
          <p>
            <AppText
              values={{
                adAccounts: <b>"Ad Accounts"</b>,
                manageAdAccounts: <b>"Manage ad accounts"</b>,
              }}
            >
              {`5. Select {adAccounts} on the left. Choose your Ad Account(s) and{" "}
              switch on {manageAdAccounts}.`}
            </AppText>
            <img
              alt="instruction-step"
              src="images/fb-ad-connector/step_5.png"
              className="instruction-step-div-img"
            />
          </p>
        </div>
        <div className="instruction-step-div">
          <p>
            <AppText>6. Click Save Changes</AppText>
          </p>
        </div>
      </div>

      <p>
        <b>
          <AppText>Current Status:</AppText>{" "}
        </b>
      </p>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
        <AppText>Ad Account</AppText>{" "}
        <b style={{ marginLeft: 5 }}> "{selectedFbAccount?.name}"</b>:{" "}
        {loading ? (
          <Oval
            height={20}
            width={20}
            color={"var(--color-primary)"}
            wrapperStyle={{
              display: "inline",
              marginTop: "5px",
              marginLeft: "5px",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="var(--text-grey)"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <AppText>{status ? "GRANTED" : "NOT YET GRANTED"}</AppText>
        )}
        {!loading && (
          <FontAwesomeIcon
            style={{ marginLeft: 5 }}
            fontSize={20}
            color={status ? "#23BD0F" : "#ED4C4C"}
            icon={status ? faCheckCircle : faCircleXmark}
          />
        )}
      </div>

      <AppButton
        onClick={() => (status ? goToConnectedPage() : checkStatus())}
        className="fb-manager-access-btn"
        title={status ? "CONTINUE" : "CHECK"}
      />
    </div>
  );
};

export default InstructionStep;
