import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { PersistGate } from "redux-persist/integration/react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://99454e47c98a4649808fe2dff8bfa7b4@o1150619.ingest.sentry.io/6223669",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

if (
  process.env.NODE_ENV !== "development" &&
  window.location.hostname !== "ad-connector-dev.firebaseapp.com"
) {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
