/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/await-thenable */

/* eslint-disable no-async-promise-executor */

import React from "react";
import Select from "react-select";
import "./styles.css";
import AppText from "components/AppText";

const SelectDropdown = (props: any) => {
  const {
    containerClassName,
    data,
    isDisabled,
    isLoading,
    name,
    getOptionValue,
    getOptionLabel,
    isClearable,
    onChange,
    defaultValue,
    formatOptionLabel,
    label,
    isMulti,
    closeMenuOnSelect,
    style,
    showClearAll,
    onPressClearAll,
  } = props;
  return (
    <div
      className={`${containerClassName} select-dropdown-container`}
      style={style}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {label && (
          <p className="select-dropdown-label">
            <AppText>{label}</AppText>
          </p>
        )}

        {showClearAll && (
          <p
            className="googledisplay-targeting-section-clear-all-text"
            onClick={onPressClearAll}
          >
            <AppText>clear all</AppText>
          </p>
        )}
      </div>
      <Select
        isMulti={isMulti}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        className="basic-single"
        classNamePrefix="select"
        defaultValue={defaultValue}
        // value={defaultValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        name={name}
        options={data}
        onChange={onChange}
        closeMenuOnSelect={closeMenuOnSelect}
        {...props}
      />
    </div>
  );
};

export default SelectDropdown;
