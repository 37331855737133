import axios from "axios";
import store from "store";
import { addPlatformService, updatePlatformService } from "./global";
import { ApiServerPath } from "api_server";

export const getSnapchatAccesstoken = async (code: any) => {
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GET_SNAPCHAT_REFRESH_TOKEN}`,
      {
        code,
      }
    );

    if (data?.result?.refresh_token) {
      await addPlatformService(
        "snapchatAds",
        data?.result?.refresh_token,
        workspaceId,
        "",
        true
      );
      return data?.result?.refresh_token;
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const getSnapchatOrganizations = async (refresh_token?: any) => {
  try {
    const { appReducer } = store.getState();
    const { platformServices = {} }: any = appReducer;
    const { snapchatAds = {} } = platformServices;
    let { refreshToken } = snapchatAds;

    refreshToken = refresh_token || refreshToken;
    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_SNAPCHAT_ORGANIZATIONS}?key=${ApiServerPath.API_SERVER_KEY}`,
      {
        refreshToken,
      }
    );

    console.log(data,'check res organ snap')

    if (data?.success) {
      return data?.results?.organizations;
    }else{
      return [];
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const getSnapchatUserInfo = async (refreshToken: any) => {
  try {
    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GET_SNAPCHAT_USER_INFO}`,
      {
        refreshToken,
      }
    );

    if (data?.result?.me) {
      updatePlatformService(
        "snapchatAds.userName",
        data?.result?.me?.display_name
      );
      return data?.result?.me?.display_name;
    }
  } catch (error) {
    console.log(error, "error");
  }
};
