import { ApiServerPath } from "api_server";
import { sweetAlert } from "./global";
import axios from "axios";

export const getSigninToken = async (uid: string) => {
  try {
    const { data }: { data: any } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.CREATE_USER_WITH_TOKEN}`,
      {
        uid,
      }
    );

    if (data?.success) {
      return data?.customToken;
    } else if (data?.error?.message) {
      sweetAlert(data?.error?.message);
    }
  } catch (error) {
    console.log(error, "error");
  }
};
