import { CustomdomainResponse, CustomdomainRequest } from "@plai-io/shared";
import axios from "axios";

const API_SERVER_URL = "https://api.plai.io";

const CallApiServer = async <REQ, RES>(
  path: string,
  request: REQ
): Promise<{
  success: boolean;
  results?: RES;
}> => {
  try {
    const { data } = await axios.post<{
      success: boolean;
      results?: RES;
    }>(`${API_SERVER_URL}${path}`, request);
    return data;
  } catch (error) {
    return { success: false };
  }
};

export const ApproveCustomDomain = async (request: {
  documentId: string;
  type: "WhiteLabel" | "BlackLabel";
  custom_domain: string;
}): Promise<{
  success: boolean;
  results?: CustomdomainResponse;
}> => {
  return CallApiServer<CustomdomainRequest, CustomdomainResponse>(
    "/operations/custom_domain",
    {
      documentId: request.documentId,
      type: request.type,
      operation: "MARK_AS_APPROVED_IF_HTTPS_WORKS",
      custom_domain: request.custom_domain,
    }
  );
};
