import { db } from "config/firebase";
import { collection } from "firebase/firestore";

export const PLATFORM_SERVICES_COLLECTION = collection(db, "platformServices");

export const USER_DATA_AND_CHATBOT_COLLECTION = collection(
  db,
  "userDataAndChatbot"
);

export const API_ERRORS_COLLECTION = collection(db, "apiErrors");
