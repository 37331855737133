/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

// styles
import "./styles.css";
import AppButton from "components/AppButton";

type AppPopoverProps = {
  open: boolean;
  text: string;
  onClose: () => void;
  isShowIcon?: boolean;
  anchorEl?: HTMLButtonElement | null;
  isShowConfirmBtn?: boolean;
  isShowCancelBtn?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  styles?: any;
};

export const AppPopover = (props: AppPopoverProps) => {
  const {
    open,
    text,
    onClose,
    isShowIcon,
    anchorEl,
    isShowCancelBtn,
    isShowConfirmBtn,
    onConfirm,
    onCancel,
    styles,
  } = props;

  return (
    <div>
      <Popover
        // id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal:'right',
          vertical:'top'

        }}
        sx={[{ zIndex: "1111111111111111111111" }, styles]}
      >
        <div className="app-popover-container">
          {isShowIcon && (
            <FontAwesomeIcon
              icon={faCircleCheck}
              color={"rgb(79, 126, 88)"}
              style={{ cursor: "pointer", marginRight: "10px" }}
              fontSize={50}
            />
          )}
          <div className="popover-content-container">
            <Typography sx={{ color: "var(--text-grey)", fontSize: "0.9rem" }}>
              {text}
            </Typography>
            {(isShowConfirmBtn || isShowCancelBtn) && (
              <div className="popover-btn-container">
                {isShowConfirmBtn && (
                  <AppButton
                    title={"Select"}
                    className={"popover-confirm-btn"}
                    onClick={() => onConfirm && onConfirm()}
                  />
                )}
                {isShowCancelBtn && (
                  <AppButton
                    title={"No thanks"}
                    className={"popover-cancel-btn"}
                    onClick={() => onCancel && onCancel()}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
