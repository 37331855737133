/* eslint-disable react-hooks/exhaustive-deps */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ConnectPlatforms from "pages/ConnectPlatforms";
import ConnectDomain from "pages/ConnectDomain";
import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { IntlProvider } from "react-intl";
import { ApiServerPath } from "api_server";

const App = () => {
  const [language, setLanguage] = useState("US");
  const [messages, setMessages] = useState<any>([]);

  useEffect(() => {
    if (window.location.search) {
      const url = new URL(window.location.href);
      const params: any = new URLSearchParams(url.search);
      setLanguage(params?.get("lang"));
      getLanguageData(params?.get("lang"));
    }
  }, []);

  const getLanguageData = async (lang: string) => {
    try {

      const data = await axios.post(
        `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_TRANSLATIONS}`
      );
      if (data?.data?.results?.translations) {
        loadLocaleData(lang, data?.data?.results?.translations);
      }
    } catch (e) {
      console.log(e, "errror");
    }
  };

  const loadLocaleData = (locale: string, translations: any) => {
    switch (locale) {
      case "DE":
        setMessages(translations.german);
        break;
      case "ES":
        setMessages(translations.spanish);
        break;
      case "PT":
        setMessages(translations.portuguese);
        break;
      case "US":
        setMessages(translations.english);
        break;
      default:
        setMessages(translations.english);
        break;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ConnectPlatforms />,
    },
    {
      path: "connectDomain",
      element: <ConnectDomain />,
    },
  ]);

  return (
    <IntlProvider locale={language} messages={messages}>
      <RouterProvider router={router} />
    </IntlProvider>
  );
};

export default App;
