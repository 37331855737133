/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import * as React from 'react';
import Card from '@mui/material/Card';

const MaterialCard = (props: any) => {
  const { style, onClick, elevation } = props;

  return (
    <Card
      style={style}
      onClick={onClick}
      elevation={elevation}
    >
      {props.children}
    </Card>
  );
};

export default MaterialCard;
