/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useGoogleLogin } from "@react-oauth/google";
import AppText from "components/AppText";

const GoogleScopeLogin = (props: any) => {
  const { scope, render, onSuccess } = props;
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => onSuccess(codeResponse),
    flow: "auth-code",
    scope: scope,
    ux_mode: "popup",
  });

  return render ? (
    render({ onClick: login })
  ) : (
    <button onClick={login} className="change-btn">
      <AppText>Change Account</AppText>
    </button>
  );
};

export default GoogleScopeLogin;
