/* eslint-disable array-callback-return */
import axios from "axios";
import store from "store";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

// Constants
import { PLATFORM_SERVICES_COLLECTION } from "constants/global/firebase-collections";
import { GOOGLE_ADS_SCOPES } from "constants/googleAds";

// Funtions
import {
  FIREBASE_EVENTS,
  addPlatformService,
  sweetAlert,
  updatePlatformServices,
} from "./global";
import qs from "qs";
import { ApiServerPath } from "api_server";

export const getGoogleAccountIssues = async (
  customerid: string,
  managerId: string
) => {
  try {
    const { platformServices = {}, userData = {} }: any =
      store.getState()?.appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;
    const { googleAdwords = {} } = platformServices;

    const { refreshToken } = googleAdwords;

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_GOOGLE_ACCOUNT_ISSUES}`,
      {
        refreshToken,
        managerId,
        customerid,
        workspaceId,
      }
    );

    if (data?.results?.message) {
      if (
        data?.results?.message ===
        "Your billling setup is not approved in Google Ads. Please contact your Google Ads manager to approve your billing setup."
      ) {
        return `Your billing information is not up-to-date in your Google Ads Manager. Please follow these instructions to add or update your billing information:\n https://support.google.com/youtube/answer/2375375?hl=en`;
      } else {
        return data?.results?.message;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error(error, "error");
  }
};

export const getGoogleAdsAccounts = async (refreshToken: any) => {
  try {
    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_API_SERVER_ADWORDS_AD_ACCOUNTS}  `,
      { refreshToken }
    );

    if (data?.results?.accounts) {
      const { accounts } = data?.results;
      for (let index = 0; index < accounts.length; index++) {
        const element = accounts[index];
        if (element?.childAccounts?.length) {
          element.childAccounts.map((item: any) => {
            item.managerId = element?.customerId;
          });
        }
        element.name = element?.name || element?.customerId;
      }
      return accounts;
    }
    return [];
  } catch (error) {
    console.log("getAccounts error in adwords *** ", error);
  }
};

export const getRefreshToken = async (serverAuthCode: any, platform?: any) => {
  const hostname = window.location.hostname;

  const {
    REACT_APP_CLIENT_ID,
    REACT_APP_CLIENT_SECRET,
    REACT_APP_GET_ACCESS_OR_REFRESH_TOKEN,
  } = process.env;

  const { userData = {} }: any = store.getState().appReducer;
  const { lastSelectedWorkspace = {} } = userData;
  const { workspaceId } = lastSelectedWorkspace;

  const data = {
    code: serverAuthCode,
    client_id: REACT_APP_CLIENT_ID,
    grant_type: "authorization_code",
    client_secret: REACT_APP_CLIENT_SECRET,
    redirect_uri:
      hostname === "localhost"
        ? "http://localhost:3000"
        : "https://app.advertgenerator.com",
  };
  const options: any = {
    method: "POST",
    data: qs.stringify(data),
    url: REACT_APP_GET_ACCESS_OR_REFRESH_TOKEN,
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  try {
    const reqForRefreshToken: any = await axios(options);
    const refreshToken = reqForRefreshToken?.data?.refresh_token;
    const responseData = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      {
        headers: {
          Authorization: `Bearer ${reqForRefreshToken?.data?.access_token}`,
        },
      }
    );
    if (platform === "googleAdwords") {
      const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);
      const getData = await getDoc(ref);
      const data = getData.data();
      if (getData.exists()) {
        if (data?.googleAdwords) {
          updatePlatformServices(
            "googleAdwords.userName",
            responseData?.data?.name
          );
          updatePlatformServices(
            "googleAdwords.email",
            responseData?.data?.email
          );
        } else {
          updateDoc(ref, {
            googleAdwords: {
              userName: responseData?.data?.name,
              email: responseData?.data?.email,
            },
          });
        }
      } else {
        setDoc(ref, {
          googleAdwords: {
            userName: responseData?.data?.name,
            email: responseData?.data?.email,
          },
        });
      }
    }

    return refreshToken;
  } catch (err) {
    console.log(err);
    throw new Error((err as any).message);
  }
};

export const updatePlatforms = async (platform: any, result: any) => {
  try {
    const { code } = result;
    const refreshToken = await getRefreshToken(code, platform);
    const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env;
    const { data } = await axios.post(
      "https://www.googleapis.com/oauth2/v4/token",
      {
        refresh_token: refreshToken,
        client_id: REACT_APP_CLIENT_ID,
        client_secret: REACT_APP_CLIENT_SECRET,
        grant_type: "refresh_token",
      }
    );

    let isScope;
    if (data?.scope) {
      if (platform === "googleAdwords") {
        isScope = data.scope.indexOf(GOOGLE_ADS_SCOPES) !== -1;
      } else if (platform === "googleAnalytics") {
        isScope =
          data.scope.indexOf(
            "https://www.googleapis.com/auth/analytics.readonly"
          ) !== -1 &&
          data.scope.indexOf(
            "https://www.googleapis.com/auth/analytics.manage.users"
          ) !== -1;
      } else {
        isScope =
          data.scope.indexOf(
            "https://www.googleapis.com/auth/youtube.readonly"
          ) !== -1 &&
          data.scope.indexOf(
            "https://www.googleapis.com/auth/yt-analytics-monetary.readonly"
          ) !== -1;
      }
    }

    if (isScope) {
      const { userData = {} }: any = store.getState().appReducer;
      const { lastSelectedWorkspace = {} } = userData;
      const { workspaceId } = lastSelectedWorkspace;
      addPlatformService(platform, refreshToken, workspaceId);
      const screenName =
        platform === "googleAnalytics"
          ? "Google Analytics Analytics"
          : platform === "googleAdwords"
          ? "Google Ads Analytics"
          : "Youtube Analytics";

      FIREBASE_EVENTS("view_content", screenName);
    } else {
      setTimeout(() => {
        sweetAlert(
          `You must allow access to ${
            platform === "googleAdwords"
              ? "Google Ads"
              : platform === "googleAnalytics"
              ? "Google Analytics"
              : "Youtube Analytics"
          } permissions to continue.`
        );
      }, 1500);
    }
  } catch (error) {
    sweetAlert(error, "error", null);
  }
};
