import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppText from "components/AppText";

const AppModal = (props: any) => {
  const {
    open,
    title,
    children,
    onScroll,
    className,
    modalWidth,
    handelDone,
    handleClose,
    handleCancel,
    doneButtonText,
    cancelButtonText,
    dialogContentStyle,
  } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth={true}
      onScroll={onScroll}
      className={className}
      onClose={handleClose}
      maxWidth={modalWidth || "xs"}
    >
      {title && <DialogTitle id={"alert-dialog-title"}>{title}</DialogTitle>}
      <DialogContent style={dialogContentStyle}>{children}</DialogContent>
      {(handleCancel || handelDone) && (
        <DialogActions>
          <Button onClick={handleCancel}>
            <AppText>{cancelButtonText || "Cancel"}</AppText>
          </Button>
          <Button onClick={handelDone}>
            <AppText>{doneButtonText && "Ok"}</AppText>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AppModal;
