/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { generateKey, isOnline, sweetAlert } from "functions/global";
import "./styles.css";

import Input from "components/Input";
import GoogleScopeLogin from "components/GoogleScopeLogin";
import AppButton from "components/AppButton";
import { updatePlatforms } from "functions/googleAds";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { YES_NO_RADIO_BTN } from "constants/googleAds";
import { IS_GOOGLE_DISABLED } from "constants/global/flags";
import AppText from "components/AppText";

const SocialAdsAccountSelection = (props: any) => {
  const {
    type,
    state,
    loader,
    connect,
    switchAccount,
    isConnectLoading,
    adsAccountDetails = {},
    switchPropertyId,
    switchViewId,
    propertyLoader,
    viewIdLoader,
    googleAdAccountId,
    setGoogleAdAccountId,
    setIsGoogleAccManager,
    isGoogleAccManager,
    setIsAddAdAccModal,
    setIsShowSelectedAccIds,
    googleSelectedAdAccountList,
    switchSnapchatOrganization,
    organizationLoader,
  } = props;

  let {
    accountId,
    accountName,
    isConnected,
    refreshToken,
    userName,
    propertyId,
    viewId,
    propertyName,
    viewName,
    organizationName,
    organizationId,
  } = adsAccountDetails;

  const scopes =
    "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adwords";

  // const scopes =
  // "https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adwords";

  isConnected = type === "TIKTOK" ? refreshToken || accountId : isConnected;

  console.log(type, "type");

  const platformName =
    type === "GOOGLE_ADS_ANALYTICS" || type === "GOOGLE"
      ? "googleAdwords"
      : type === "YOUTUBE_ANALYTICS"
        ? "youtubeAnalytics"
        : "googleAnalytics";

  let splitProperty: any = [];
  if (propertyId) {
    splitProperty = propertyId.split("-");
  }

  const _renderSocialAdsAccountSelection = (
    onClickActionText?: any,
    isTiktok?: any
  ) => {
    let redirect_uri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/"
        : "https://app.advertgenerator.com/";
    return (
      <div className="social-ads-acc-selection-container">
        {isConnected ? (
          <>
            <p className="m-0 mb-2 social-ads-acc-selection-heading">
              {type?.replace(/_/g, " ")} <AppText>PROFILE</AppText>
            </p>
            <div className={"social-ads-acc-selection-input-container"}>
              <Input
                disabled
                loginType={type}
                className={"mb-4 k-cursor-pointer"}
                onClickActionText={onClickActionText}
                value={
                  isTiktok && isConnected
                    ? "TiktokAds"
                    : type === "GOOGLE_ANALYTICS" && isConnected
                      ? "Google Analytics"
                      : userName || "User Name"
                }
                actionText={`${isConnectLoading
                  ? "Loading..."
                  : isConnected
                    ? "Switch"
                    : "Connect"
                  }`}
                tiktokRedirectLink={
                  type === "SNAPCHAT"
                    ? `https://accounts.snapchat.com/login/oauth2/authorize?client_id=f4ab840f-784b-4589-9cef-262f11699192&state=${JSON.stringify(
                      state
                    )}&redirect_uri=https://app.advertgenerator.com/&response_type=code&scope=snapchat-marketing-api%20snapchat-profile-api`
                    : type === "LINKEDIN"
                      ? `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86bqa8ga1qgt90&redirect_uri=${redirect_uri}&state=${JSON.stringify(
                        state
                      )}&scope=r_marketing_leadgen_automation%2Cr_liteprofile%2Cr_events%2Cr_ads_leadgen_automation%2Cr_1st_connections_size%2Cr_ads%2Cr_ads_reporting%2Cr_basicprofile%2Cr_organization_admin%2Cr_organization_social%2Crw_ads%2Crw_organization_admin%2Cw_member_social%2Cw_organization_social`
                      : type === "BING_ADS"
                        ? `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=e5153ce5-a687-4eea-a9ec-2067cb483c65&response_type=code&redirect_uri=https://app.advertgenerator.com/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fads.microsoft.com%2Fmsads.manage&state=${JSON.stringify(
                          state
                        )}&sso_reload=true`
                        : type === "SPOTIFY"
                          ? `https://accounts.spotify.com/authorize/?client_id=698dbc0c45ca469288c93ece575dc370&response_type=code&redirect_uri=https://app.advertgenerator.com/&state=${JSON.stringify(
                            state
                          )}`
                          : `https://ads.tiktok.com/marketing_api/auth?app_id=6932899027516129282&state=${JSON.stringify(
                            state
                          )}&redirect_uri=https://app.advertgenerator.com/`
                }
              />
            </div>
          </>
        ) : (
          <AppButton
            title={"Connect"}
            className={"social-ads-acc-connect-btn"}
            onClick={() => {
              type === "SNAPCHAT"
                ? window.open(
                  `https://accounts.snapchat.com/login/oauth2/authorize?client_id=f4ab840f-784b-4589-9cef-262f11699192&state=${JSON.stringify(
                    state
                  )}&redirect_uri=https://app.advertgenerator.com/&response_type=code&scope=snapchat-marketing-api%20snapchat-profile-api`,
                  "_self"
                )
                : type === "LINKEDIN"
                  ? window.open(
                    `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86bqa8ga1qgt90&redirect_uri=${redirect_uri}&state=${JSON.stringify(
                      state
                    )}&scope=r_marketing_leadgen_automation%2Cr_liteprofile%2Cr_events%2Cr_ads_leadgen_automation%2Cr_1st_connections_size%2Cr_ads%2Cr_ads_reporting%2Cr_basicprofile%2Cr_organization_admin%2Cr_organization_social%2Crw_ads%2Crw_organization_admin%2Cw_member_social%2Cw_organization_social`,
                    "_self"
                  )
                  : type === "BING_ADS"
                    ? window.open(
                      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=e5153ce5-a687-4eea-a9ec-2067cb483c65&response_type=code&redirect_uri=https://app.advertgenerator.com/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fads.microsoft.com%2Fmsads.manage&state=${JSON.stringify(
                        state
                      )}&sso_reload=true`,
                      "_self"
                    )
                    : type === "SPOTIFY"
                      ? window.open(
                        `https://accounts.spotify.com/authorize/?client_id=698dbc0c45ca469288c93ece575dc370&response_type=code&redirect_uri=https://app.advertgenerator.com/&state=${JSON.stringify(
                          state
                        )}`,
                        "_self"
                      )
                      : type === "TIKTOK"
                        ? window.open(
                          `https://ads.tiktok.com/marketing_api/auth?app_id=6932899027516129282&state=${JSON.stringify(
                            state
                          )}&redirect_uri=https://app.advertgenerator.com/`,
                          "_self"
                        )
                        : onClickActionText();
            }}
          />
        )}

        {!!isConnected && type === "SNAPCHAT" && (
          <>
            <p className="m-0 mb-2 mt-2 social-ads-acc-selection-heading">
              <AppText>SELECT ORGANIZATION</AppText>
            </p>
            <div className={"social-ads-acc-selection-input-container"}>
              <Input
                disabled
                onClickActionText={switchSnapchatOrganization}
                value={organizationName || "Organization"}
                actionText={`${organizationLoader
                  ? "Loading..."
                  : organizationId
                    ? "Change"
                    : "Select"
                  }`}
              />
            </div>
          </>
        )}

        {!!isConnected &&
          (type === "GOOGLE" && IS_GOOGLE_DISABLED ? (
            !googleSelectedAdAccountList.length ? (
              <AppButton
                title={"Add new Ad Accounts"}
                className={"social-ads-acc-connect-btn"}
                onClick={() => setIsAddAdAccModal(true)}
              />
            ) : (
              <div className="google-ad-account-input-container">
                <p className="google-ad-account-input-label">
                  <AppText>ENTER AD ACCOUNT ID</AppText>
                </p>
                <Input
                  placeholder="Example: 111-000-1234"
                  value={googleAdAccountId}
                  onChange={(e: any) => {
                    if (e.target.value.length === 3) {
                      const text = e.target.value.substring(0, 3) + "-";
                      setGoogleAdAccountId(text);
                    } else if (e.target.value.length === 7) {
                      const text = e.target.value.substring(0, 7) + "-";
                      setGoogleAdAccountId(text);
                    } else {
                      setGoogleAdAccountId(e.target.value);
                    }
                  }}
                  maxLength={12}
                  onKeyUp={(e: any) => {
                    if (e.key === "Backspace") {
                      if (e.target.value.length === 4) {
                        setGoogleAdAccountId(e.target.value.substring(0, 3));
                      } else if (e.target.value.length === 8) {
                        setGoogleAdAccountId(e.target.value.substring(0, 7));
                      }
                    }
                  }}
                  actionText={"Select Ad Account"}
                  onClickActionText={() => {
                    if (googleSelectedAdAccountList.length) {
                      setIsShowSelectedAccIds(true);
                    } else {
                      sweetAlert(
                        `You don't have added google Ad accounts. Please add new ad account.`
                      );
                    }
                  }}
                  disabled
                />
                <div className="google-disable-ad-acc-text-container">
                  <p
                    className="google-disable-ad-acc-text"
                    onClick={() => setIsAddAdAccModal(true)}
                  >
                    <AppText>Add new ad account</AppText>
                  </p>
                </div>

                <div className="google-disable-manager-radio-btn">
                  <p className="google-disable-manager-radio-btn-heading">
                    <AppText>Are you the Google Ad Account Admin?</AppText>
                  </p>
                  {YES_NO_RADIO_BTN.map((item: { name: string }) => {
                    const { name } = item;

                    return (
                      <div
                        key={generateKey()}
                        onClick={() => setIsGoogleAccManager(name)}
                        className="google-disable-radio-btn-container"
                      >
                        <p className="google-disable-radio-btn-text">{name}</p>
                        {name === isGoogleAccManager ? (
                          <img
                            alt=""
                            src="images/global/check.png"
                            className="google-disable-radio-btn"
                          />
                        ) : (
                          <div
                            className={"google-disable-radio-btn-unchecked"}
                          />
                        )}
                      </div>
                    );
                  }).reverse()}
                </div>
                {/* <p className="google-disable-note">
              You can expect to receive an invitation to your email address, {email}, within 24 hours after you have created your ad campaign. We kindly request that you promptly accept this invitation in order for your ad campaign to be successfully created.
            </p> */}
              </div>
            )
          ) : accountId ? (
            <>
              <p className="m-0 mb-2 social-ads-acc-selection-heading">
                <AppText>
                  {type === "GOOGLE_ANALYTICS"
                    ? "SELECT ACCOUNT"
                    : "SELECT AD ACCOUNT"}
                </AppText>
              </p>
              <div className={"social-ads-acc-selection-input-container"}>
                <Input
                  disabled
                  onClickActionText={switchAccount}
                  value={accountName || "Ad Account"}
                  actionText={`${loader ? "Loading..." : accountId ? "Change" : "Select"
                    }`}
                />
              </div>
            </>
          ) : (
            <AppButton
              title={"Select Ad Account"}
              onClick={switchAccount}
              className={"social-ads-acc-connect-btn"}
              loading={loader}
            />
          ))}


        {!!accountId && type === "GOOGLE_ANALYTICS" && (
          <>
            <p className="m-0 mb-2 mt-2 social-ads-acc-selection-heading">
              <AppText>SELECT PROPERTY</AppText>
            </p>
            <div className={"social-ads-acc-selection-input-container"}>
              <Input
                disabled
                onClickActionText={switchPropertyId}
                value={propertyName || "Property Id"}
                actionText={`${propertyLoader
                  ? "Loading..."
                  : propertyId
                    ? "Change"
                    : "Select"
                  }`}
              />
            </div>
          </>
        )}

        {propertyId &&
          splitProperty[0] === "UA" &&
          type === "GOOGLE_ANALYTICS" && (
            <>
              <p className="m-0 mb-2 mt-2 social-ads-acc-selection-heading">
                <AppText>SELECT VIEW</AppText>
              </p>
              <div className={"social-ads-acc-selection-input-container"}>
                <Input
                  disabled
                  onClickActionText={switchViewId}
                  value={viewName || "View Id"}
                  actionText={`${viewIdLoader ? "Loading..." : viewId ? "Change" : "Select"
                    }`}
                />
              </div>
            </>
          )}
      </div>
    );
  };

  const handleGoogleAdwordsConnect = (renderProps: any) => {
    if (isOnline()) {
      renderProps.onClick();
    } else {
      sweetAlert(
        "Please make sure your device is connected to internet!",
        "error",
        null
      );
    }
  };

  return (
    <div className="ad-account-selection-container">
      {type === "TIKTOK" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect, true)
      ) : type === "SNAPCHAT" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect)
      ) : type === "LINKEDIN" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect)
      ) : type === "BING_ADS" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect)
      ) : type === "SPOTIFY" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect)
      ) : type === "FACEBOOK" ? (
        _renderSocialAdsAccountSelection(!isConnectLoading && connect)
      ) : (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ""}>
          <GoogleScopeLogin
            scope={scopes}
            onSuccess={(response: any) =>
              updatePlatforms(platformName, response)
            }
            render={(renderProps: any) =>
              _renderSocialAdsAccountSelection(() =>
                handleGoogleAdwordsConnect(renderProps)
              )
            }
          />
        </GoogleOAuthProvider>
      )}
    </div>
  );
};

export default SocialAdsAccountSelection;
