import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import AppText from "components/AppText";

const Connected = (props: any) => {
  const { selectedFbAccount, adminDetails } = props;
  return (
    <div className="connected-ad-account-container">
      <div className="connected-ad-account-sub-container">
        <FontAwesomeIcon
          className="connected-ad-account-check-icon"
          icon={faCheckCircle}
        />
        <p>
          <AppText>Access Granted</AppText>
        </p>
        <div>
          <p className="connect-ad-account-text">
            <AppText>Ad Account:</AppText> {selectedFbAccount?.name}{" "}
            {`(${selectedFbAccount?.id})`}
          </p>
        </div>
      </div>

      <div className="connect-ad-account-text">
        <AppText
          values={{
            managerName: <b>{adminDetails?.businessManagerName}</b>,
            link: (
              <a
                rel="noreferrer"
                href={`https://business.facebook.com/settings/partners/${adminDetails?.businessManagerId}?business_id=${selectedFbAccount?.business?.id}`}
                target="_blank"
              >
                link
              </a>
            ),
          }}
        >
          {`Click this {link} to view the assets shared with {managerName} in your business manager`}
        </AppText>
      </div>
    </div>
  );
};

export default Connected;
