/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import AppText from "components/AppText";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleScopeLogin from "components/GoogleScopeLogin";
import {
  getGoogleAccountIssues,
  getGoogleAdsAccounts,
  getRefreshToken,
} from "functions/googleAds";
import MaterialTreeView from "components/MaterialTreeView";
import { sweetAlert } from "functions/global";
import "./styles.css";
import AppButton from "components/AppButton";

const GoogleLinkBridge = ({ connectId }: any) => {
  const [loading, setLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);
  const [accountSelectLoading, setAccountSelectLoading] = useState(false);
  const [accessLoading, setAccessLoading] = useState(false);
  const [adminDetails, setAdminDetails] = useState<any>(null);
  const [errorFromAdmin, setErrorFromAdmin] = useState("");
  const [googleRefreshToken, setGoogleRefreshToken] = useState<any>(null);
  const [googleConnected, setGoogleConnected] = useState(false);
  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState<any>(null);
  const [adAccounts, setAdAccounts] = useState<any>([]);
  const [accessMessage, setAccessMessage] = useState("");

  const scopes =
    "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adwords";

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://api.plai.io/firebase/get_business_manager",
        {
          adminId: connectId,
          platform: "GOOGLE",
        }
      );
      console.log(data, "data respp");
      setAdminDetails(data.results.business);
    } catch (e: any) {
      setErrorFromAdmin(e.response.data.results.error);
      console.log(e, "Eee");
    } finally {
      setLoading(false);
    }
  };

  const provideAccess = async () => {
    try {
      setAccessLoading(true);
      setAccessMessage("");
      const { data } = await axios.post(
        "https://api.plai.io//googleads/link_google_mcc_account",
        {
          customerName: selectedGoogleAccount.name,
          customerId: selectedGoogleAccount.customerId,
          refreshToken: googleRefreshToken,
          adminId: connectId,
          managerId: selectedGoogleAccount.managerId, //optional
        }
      );
      setAccessMessage(
        data.success
          ? "Your account is now linked"
          : data.results.error || "something went wrong, please contact support"
      );
    } catch (e: any) {
      setAccessMessage(
        e.response.data.results.error ||
          "something went wrong, please contact support"
      );
    } finally {
      setAccessLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onConnectGoogle = async (resp: any) => {
    setGoogleLoginLoading(true);
    const refreshToken = await getRefreshToken(resp.code);
    console.log(refreshToken, "refresh token");
    if (refreshToken) {
      setGoogleRefreshToken(refreshToken);
      const accounts = await getGoogleAdsAccounts(refreshToken);
      console.log(accounts, "accounts====");
      setAdAccounts(accounts);
      setGoogleConnected(true);
    }
    setGoogleLoginLoading(false);
  };

  const onSelectGoogleAccount = async (item: any) => {
    console.log(item, "selected g account");
    try {
      setAccountSelectLoading(true);
      const { customerId, isManager, managerId } = item;
      if (!isManager) {
        const accIssue = await getGoogleAccountIssues(customerId, managerId);

        if (accIssue && customerId !== 8211450874) {
          sweetAlert(accIssue);
        } else {
          setSelectedGoogleAccount(item);
        }
      } else {
        sweetAlert("You can not select manager account.");
      }
    } finally {
      setAccountSelectLoading(false);
    }
  };

  const renderAdAccounts = () => {
    if (googleConnected) {
      if (adAccounts?.length > 0) {
        return (
          <>
            <div style={{ width: "100%", maxHeight: 250, overflow: "auto" }}>
              <p className="google-manager-access-connect-heading">
                Select ad account
              </p>
              <div className="google-manager-access-account-selection-card">
                <MaterialTreeView
                  data={adAccounts}
                  onSelect={(item: any) => onSelectGoogleAccount(item)}
                  onChildSelect={(item: any) => onSelectGoogleAccount(item)}
                />
              </div>
            </div>

            {accountSelectLoading ? (
              <p>
                <AppText>Loading...</AppText>
              </p>
            ) : (
              selectedGoogleAccount && (
                <div className="google-manager-access-selected-account-card">
                  <p style={{ marginTop: 0 }}>
                    Selected google Account: <b>{selectedGoogleAccount.name}</b>
                  </p>
                  <AppButton
                    title={"Provide access"}
                    onClick={provideAccess}
                    loading={accessLoading}
                    className="google-manager-access-access-btn"
                  />

                  {accessMessage && (
                    <p className="google-manager-access-message">
                      {accessMessage}
                    </p>
                  )}
                </div>
              )
            )}
          </>
        );
      } else {
        return <p>You don't have any ad accounts</p>;
      }
    }
  };

  const renderConnectGoogle = () => {
    return (
      <div className="google-manager-access-connect-container">
        <p className="google-manager-access-connect-heading">
          <AppText>Please connect your account to continue</AppText>
        </p>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ""}>
          <GoogleScopeLogin
            scope={scopes}
            onSuccess={(resp: any) => onConnectGoogle(resp)}
            render={(renderProps: any) => (
              <button
                className="google-manager-access-connect-btn"
                onClick={() => renderProps.onClick()}
              >
                <AppText>{googleConnected ? "Reconnect" : "Connect"}</AppText>{" "}
                Google
              </button>
            )}
          />
        </GoogleOAuthProvider>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Oval
          height={40}
          width={40}
          color={"var(--color-primary)"}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="var(--text-grey)"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <div className="google-manager-access-main-container">
          {adminDetails ? (
            <div>
              <div className="google-manager-access-card">
                <p className="google-manager-access-main-heading">
                  <AppText
                    values={{
                      managerName: adminDetails?.businessManagerName,
                    }}
                  >
                    {`{managerName} is requesting manager access to your google ads account`}
                  </AppText>
                </p>

                {googleLoginLoading ? (
                  <p>
                    <AppText>Loading...</AppText>
                  </p>
                ) : (
                  <>
                    {renderConnectGoogle()}
                    {renderAdAccounts()}
                  </>
                )}
              </div>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>
              <FontAwesomeIcon
                style={{ marginBottom: 10 }}
                fontSize={50}
                icon={faTriangleExclamation}
              />{" "}
              <br /> {errorFromAdmin}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default GoogleLinkBridge;
