/* eslint-disable no-useless-escape */
import axios from "axios";
import CustomDomain from "./components/CustomDomain";
import { ChangeEvent, useEffect, useState } from "react";
import Input from "components/Input";
import AppButton from "components/AppButton";
import "./styles.css";
import { sweetAlert } from "functions/global";
import AppText from "components/AppText";
import { ApiServerPath } from "api_server";

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ConnectDomain = () => {
  const [agencyId, setAgencyId] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [emailCheckLoader, setEmailCheckLoader] = useState(false);
  const [domainAlreadyExists, setDomainAlreadyExists] = useState(false);
  const [customDomainData, setCustomDomainData] = useState<any>(null);
  const [emailChecked, setEmailChecked] = useState(false);
  const [headerText, setHeaderText] = useState(
    "Enter your email to connect your domain or get the signup link if already connected"
  );

  useEffect(() => {
    if (window.location.search) {
      const url = new URL(window.location.href);
      console.log(url, "url string");
      const params: any = new URLSearchParams(url.search);
      const agencyId = params?.get("agencyId");
      setAgencyId(agencyId);
    }
  }, []);

  const checkIfDomainExists = async () => {
    if (!email?.trim()?.match(EMAIL_REGEX)) {
      sweetAlert("Please enter correct email.");
    } else {
      try {
        setEmailCheckLoader(true); 
        const res = await axios.post(
          `${ApiServerPath.API_SERVER_BASE_URL}/firebase/get_blacklabel_user_info`,
          {
            uid: agencyId,
            email,
          }
        );
        if (res.data.success) {
          setHeaderText(
            res?.data?.results?.data?.info?.isApproved
              ? "Awesome! Your domain has been successfully registered."
              : "Regsiter your domain"
          );
          setDomainAlreadyExists(true);
          setCustomDomainData(res.data.results.data);
        }
      } catch (e) {
        setHeaderText("Register your domain");
        setDomainAlreadyExists(false);
      } finally {
        setEmailChecked(true);
        setEmailCheckLoader(false);
      }
    }
  };

  return (
    <div className="custom-domain-main-container">
      <div className="custom-domain-content-container">
        <div className="custom-domain-content-header">
          <p>
            <AppText>{headerText}</AppText>
          </p>
        </div>
        <div className="custom-domain-content">
          {emailChecked ? (
            <CustomDomain
              domainAlreadyExists={domainAlreadyExists}
              agencyId={agencyId}
              subAdminEmail={email}
              setHeaderText={setHeaderText}
              customDomain={customDomainData}
              setCustomDomainData={setCustomDomainData}
            />
          ) : (
            <>
              <div style={{ marginBottom: 40, width: "90%" }}>
                <p>Enter email</p>
                <Input
                  placeholder="john@email.com"
                  inputChildClassName="check-domain-email-input-child"
                  className={"check-domain-email-input"}
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                />
              </div>
              <AppButton
                className="check-domain-email-button"
                loading={emailCheckLoader}
                onClick={checkIfDomainExists}
                title="Next"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectDomain;
