import axios, { AxiosError } from "axios";
import store from "store";
import { addPlatformService, updatePlatformServices } from "./global";
import { ApiServerPath } from "api_server";

export const getBingAdsAccessToken = async (authCode: string) => {
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    let redirectURI =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/Ads-login"
        : "https://app.advertgenerator.com/";

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_BING_ADS_ACCESS_TOKEN}`,
      {
        authCode,
        redirectURI,
      }
    );

    console.log(data, "bing ad data");

    if (data?.refreshToken) {
      await addPlatformService("bingAds", data?.refreshToken, workspaceId);

      updatePlatformServices("bingAds.userName", `Bing Ads`);

      return data;
    }
  } catch (error) {
    const err: any = error as AxiosError;
    console.log(err.response?.data?.Error?.error_description);
  }
};

export const getBingAdAccounts = async (refreshToken?: string) => {
  try {
    const { appReducer } = store.getState();
    const { platformServices = {} }: any = appReducer;
    const { bingAds = {} } = platformServices;
    let { refreshToken: platformRefreshToken } = bingAds;

    refreshToken = refreshToken || platformRefreshToken;

    const { data } = await axios.post(
      `${ApiServerPath.PYTHON_API_SERVER_BASE_URL}${ApiServerPath.GET_BING_ADS_AD_ACCOUNTS}`,
      {
        refresh_token: refreshToken,
        debugMode: false,
      }
    );

    console.log(data, "check data");

    if (data?.success) {
      return data?.accounts;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};
