import axios, { AxiosError } from "axios";
import store from "store";
import { addPlatformService, updatePlatformServices } from "./global";
import { CURRENCIES } from "constants/global";
import { ApiServerPath } from "api_server";

export const getSpotifyAdsAccessToken = async (code: string) => {
  console.log(code, "check code");
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    let redirect_uri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/"
        : "https://app.advertgenerator.com/";

    console.log({
      code,
      redirect_uri,
    });

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_SPOTIFY_ADS_ACCESS_TOKEN}`,
      {
        code,
        redirect_uri,
      }
    );

    console.log(data, "bing ad data");

    if (data?.success) {
      if (data?.results?.refresh_token) {
        await addPlatformService(
          "spotifyAds",
          data?.results?.refresh_token,
          workspaceId
        );

        updatePlatformServices("spotifyAds.userName", `Spotify Ads`);

        return data;
      }
    } else {
      return false;
    }
  } catch (error) {
    const err: any = error as AxiosError;
    console.log(err);
  }
};

export const getSpotifyAdAccounts = async (refreshToken?: string) => {
  try {
    const { appReducer } = store.getState();
    const { platformServices = {} }: any = appReducer;
    const { spotifyAds = {} } = platformServices;
    let { refreshToken: platformRefreshToken } = spotifyAds;

    refreshToken = refreshToken || platformRefreshToken;

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_SPOTIFY_ADS_AD_ACCOUNTS}`,
      {
        refresh_token: refreshToken,
      }
    );

    console.log(data, "check data");

    if (data?.success) {
      const response = data?.results?.ad_accounts.map((item: any) => {
        return {
          ...item,
          currencyCode:
            CURRENCIES.find((item: any) => item.country_code === item.cc)
              ?.symbol || "USD",
        };
      });

      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};
