import axios from "axios";
import store from "store";
import { addPlatformService, sweetAlert } from "./global";
import { ApiServerPath } from "api_server";

export const getTiktokAdvertiserDetail = async (
  advertiserId?: any,
  access_token?: any
) => {
  try {
    const { platformServices = {} }: any = store.getState()?.appReducer;

    const { tiktokAds = {} } = platformServices;

    let { refreshToken, mobileRefreshToken } = tiktokAds;

    const accessToken = access_token || refreshToken || mobileRefreshToken;

    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GET_TIKTOK_ADVERTISER_DETAILS}`,
      {
        accessToken,
        advertiserId,
      }
    );

    if (data?.result?.data?.length) {
      return data?.result?.data[0]?.currency;
    } else {
      console.log("error in geting details");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTikTokAccessToken = async (authCode: any) => {
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GET_TIKTOK_ACCESS_TOKEN}`,
      {
        authCode,
      }
    );

    if (data?.result?.data?.access_token) {
      const { access_token } = data?.result?.data;
      await addPlatformService("tiktokAds", access_token, workspaceId);
      return access_token;
    }
  } catch (error) {
    sweetAlert(error, "error", null);
  }
};

export const getTikTokAdvertiserAccounts = async (access_token?: any) => {
  try {
    const { platformServices = {} }: any = store.getState()?.appReducer;

    const { tiktokAds = {} } = platformServices;

    let { refreshToken, mobileRefreshToken } = tiktokAds;

    const accessToken = access_token || refreshToken || mobileRefreshToken;

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_TIKTOK_ADVERTISER_ACCOUNTS}?key=${ApiServerPath.API_SERVER_KEY}`,
      {
        accessToken,
        isMobile: access_token || refreshToken ? undefined : true,
      }
    );

    if (data?.results?.success) {
      return data?.results?.advertisers;
    }
  } catch (error) {
    sweetAlert(error, "error", null);
  }
};
