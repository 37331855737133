import { ApiServerPath } from "api_server";
import axios from "axios";

export const getGoogleAnalyticsAccount = async (refreshToken: string) => {
  try {
    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GOOGLE_ANALYTICS_ACCOUNTS}`,
      { refreshToken }
    );
    console.log(data);
    if (data?.length && typeof data !== "string") {
      return data;
    } else {
      return { error: data };
    }
  } catch (error) {
    console.log(error, "error");
    return { error: `You don't have google analytics account.` };
  }
};

export const getGoogleAnalyticsProperties = async (obj: {
  refreshToken: string;
  accountId: string;
}) => {
  try {
    const { data: newData } = await axios.post(
      `${ApiServerPath.BASE_URL}/getPropertyIdTest`,
      obj
    );
    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GOOGLE_ANALYTICS_PROPERTIES}`,
      obj
    );

    if (data?.length || newData?.properties?.length) {
      return [...data, ...newData?.properties];
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const getGoogleAnalyticsViewIds = async (obj: {
  accountId: string;
  refreshToken: string;
  propertyId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.GOOGLE_ANALYTICS_VIEWS}`,
      obj
    );

    if (data?.length) {
      return data;
    }
  } catch (error) {
    console.log(error, "error");
  }
};
