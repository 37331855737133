import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
// import JSOG from 'jsog';
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import rootReducer from "../reducers";

// export const JSOGTransform = createTransform(
// (inboundState, key) => JSOG.encode(inboundState),
// (outboundState, key) => JSOG.decode(outboundState),
// )

const persistConfig = {
  storage,
  key: "root",
  // transforms: [JSOGTransform],
};

const middleware = applyMiddleware(thunk);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, middleware);

export const persistor = persistStore(store);
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
