/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from "react";

//components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Input from "components/Input";

// styles
import "./styles.css";
import AppButton from "components/AppButton";
import { formatMessageInPlaceholder, sweetAlert } from "functions/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AppText from "components/AppText";
import axios from "axios";
import { ApproveCustomDomain } from "functions/api_server_calls";
import { ApiServerPath } from "api_server";

const CustomDomain = (props: {
  domainAlreadyExists: boolean;
  agencyId: undefined | string;
  customDomain: any;
  subAdminEmail: string;
  setHeaderText: any;
  setCustomDomainData: any;
}) => {
  const {
    customDomain = {},
    domainAlreadyExists,
    agencyId,
    subAdminEmail,
    setHeaderText,
    setCustomDomainData,
  } = props;

  const [domainName, setDomainName] = useState<string>(
    customDomain?.info?.custom_domain || ""
  );
  const [domainError, setDomainError] = useState<boolean>(false);
  const [domainLoader, setDomainLoader] = useState<boolean>(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [isDomainSubmitted, setIsDomainSubmitted] = useState(false);
  const [newCustomDomainDocId, setNewCustomDomainDocId] = useState<
    null | string
  >(null);
  const [newCustomDomainName, setNewCustomDomainName] = useState<null | string>(
    null
  );
  const [signupLink, setSignupLink] = useState<null | string>(null);
  //Funtions
  const createData = (type: string, host: string, value: string) => {
    return { type, host, value };
  };

  const rows = [createData("CNAME", domainName, "secure.aiadvertservice.com")];

  const validateEnterDomain = async () => {
    const urlRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
    setDomainLoader(true);
    if (domainName.length > 0 && urlRegex.test(domainName)) {
      setDomainError(false);
      setIsDomainSubmitted(true);
      setDomainLoader(false);
      await validateGoliveDomain();
    } else {
      setDomainError(true);
      setDomainLoader(false);
    }
  };

  useEffect(() => {
    if (customDomain?.info?.isApproved) {
      setSignupLink(
        `https://${domainName}/?ref_id=${agencyId}&access=MANAGE&domain_ref=${customDomain?.customDomainId}`
      );
    }
  }, []);

  const handlePublishDomain = async () => {
    const domainRef = domainAlreadyExists
      ? customDomain?.customDomainId
      : newCustomDomainDocId || "";
    try {
      setPublishLoader(true);
      const res: any = await ApproveCustomDomain({
        documentId: domainRef,
        type: "BlackLabel",
        custom_domain: domainName,
      });

      if (res.results?.success) {
        setHeaderText("Awesome! Your domain has been successfully registered.");
        setSignupLink(
          `https://${domainName}/?ref_id=${agencyId}&access=MANAGE&domain_ref=${domainRef}`
        );
      } else {
        sweetAlert(
          res.results.errormessage ||
            "Something went wrong, please contact support"
        );
      }
    } catch (e) {
      sweetAlert("Something went wrong, please contact support");
    } finally {
      setPublishLoader(false);
    }
  };

  const validateGoliveDomain = () => {
    const data = domainAlreadyExists
      ? {
          isEdit: true,
          domainName,
          customDomainId: customDomain?.customDomainId,
        }
      : {
          isEdit: false,
          domainName,
          subAdminEmail: subAdminEmail,
          currentUserUid: agencyId,
        };

    if (domainAlreadyExists && customDomain.info.custom_domain === domainName) {
      sweetAlert("Please enter a different domain name to update");
    } else {
      saveCustomDomainInfo(data);
    }
  };

  const saveCustomDomainInfo = async (data: any) => {
    try {
      setDomainLoader(true); 
      const res = await axios.post(
        `${ApiServerPath.API_SERVER_BASE_URL}/firebase/save_custom_domains_info`,
        data
      );
      if (res.data.success) {
        if (res.data.results.customDomainInfo) {
          setCustomDomainData(res?.data?.results?.customDomainInfo);
          setNewCustomDomainName(
            res?.data?.results?.customDomainInfo?.info?.custom_domain
          );
        }
        setNewCustomDomainDocId(res?.data?.results?.customDomainInfo?.customDomainId);
      }
    } catch (e) {
      console.log(e, "error");
    } finally {
      setDomainLoader(false);
    }
  };

  // Render

  const renderEnterDomain = (isCreate?: boolean) => {
    return (
      <>
        <Typography fontSize={14} mb={3} color="#333" variant="subtitle1">
          <AppText>Step 1: Submit your custom domain</AppText>

          <Tooltip
            arrow
            title={formatMessageInPlaceholder(
              `consider setting up your domain as a sub domain by adding the word 'ads' or 'adcenter' before your main domain.`
            )}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              fontSize={16}
              style={{ margin: "0 5px" }}
            />
          </Tooltip>
        </Typography>
        <div className="enter-domain-input-button">
          <Input
            value={domainName}
            onChange={(e: { target: { value: string } }) => {
              setDomainName(e.target.value);
            }}
            className="enter-domain-input-parent"
            placeholder="ads.example.com"
            inputChildClassName="enter-domain-input"
          />
          {isCreate && (
            <AppButton
              title={domainAlreadyExists ? "Update" : "Submit"}
              onClick={() => validateEnterDomain()}
              className={"enter-domain-button"}
              loading={domainLoader}
            />
          )}
        </div>
        {domainError && (
          <Typography
            sx={{ marginLeft: 1, marginTop: 1 }}
            variant="subtitle2"
            color="red"
          >
            Please enter a valid domain
          </Typography>
        )}
      </>
    );
  };

  const renderVerifyDomain = () => {
    return (
      <>
        <Typography
          fontSize={14}
          mb={2}
          mt={3}
          color="#333"
          variant="subtitle1"
        >
          <AppText>
            Step 2: Add the CNAME records below to your DNS provider to verify
            you own
          </AppText>{" "}
          {domainName}
        </Typography>
        {isDomainSubmitted && (
          <Table sx={{ minWidth: 450 }} aria-label="domain data table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="left">Host</TableCell>
                <TableCell align="left">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell size="small" component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell align="left">{row.host}</TableCell>
                  <TableCell align="left">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </>
    );
  };

  return (
    <>
      {signupLink ? (
        <>
          <FontAwesomeIcon
            style={{ marginTop: 40, color: "green" }}
            fontSize={50}
            icon={faCircleCheck}
          />
          <p className="signup-link-success-text">
            Click the signup button and register with the email you used to
            register your domain.
          </p>

          <AppButton
            className="signup-link-button"
            title={"Signup"}
            onClick={() => window.open(signupLink)}
          />

          <p
            onClick={() => {
              setHeaderText("Regsiter your domain");
              setSignupLink(null);
            }}
            className="update-domain-text"
          >
            Review your domain
          </p>
        </>
      ) : (
        <div style={{ width: "100%" }}>
          {renderEnterDomain(true)}

          {renderVerifyDomain()}

          {(customDomain?.info?.custom_domain || newCustomDomainDocId) && (
            <>
              {!customDomain?.info?.isApproved && (
                <>
                  <Typography
                    fontSize={14}
                    mb={2}
                    mt={3}
                    color="#333"
                    variant="subtitle1"
                  >
                    <AppText
                      values={{
                        link: (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://${
                              customDomain?.info?.custom_domain ||
                              newCustomDomainName ||
                              domainName
                            }`}
                          >
                            link
                          </a>
                        ),
                      }}
                    >
                      {`Step 3: Click following {link}. If a link is working, then press
                  publish. If not, please wait a few more minutes and retry.`}
                    </AppText>
                  </Typography>

                  <AppButton
                    title={"Publish"}
                    onClick={handlePublishDomain}
                    className={"enter-domain-publish-button"}
                    loading={publishLoader}
                  />
                </>
              )}

              {customDomain?.info?.isApproved && (
                <AppButton
                  title={"Next"}
                  onClick={() => {
                    setHeaderText(
                      "Awesome! Your domain has been successfully registered."
                    );
                    setSignupLink(
                      `https://${domainName}/?ref_id=${agencyId}&access=MANAGE&domain_ref=${customDomain?.customDomainId}`
                    );
                  }}
                  className={"enter-domain-skip-button"}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CustomDomain;
