/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import "./styles.css";

const AppContainer = (props: any) => {
  const { className } = props;

  return (
    <div className={`app-container-main-container ${className}`}>
      {props.children}
    </div>
  );
};
export default AppContainer;
