import axios from "axios";
import { fbGraphApi } from "functions/facebookAds";

export const getFbPages = (tokenFromParam: string, userId: string, getBusinesses?: boolean) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `https://api.plai.io/fb_ig_ads/get_facebook_pages`,
        {
          accessToken: tokenFromParam,
          userId,
          message: "getFbPages",
          getBusinesses,
        }
      );

      console.log(response, "response of fb pages");

      if (response?.data?.results?.accounts?.data?.length) {
        const publishedPages = response?.data?.results?.accounts?.data.filter(
          (page: any) => page.is_published
        );
        resolve(publishedPages);
      } else {
        resolve([]);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getPageAccessToken = async (pageId: string) => {
  try {
    const response: any = await fbGraphApi(pageId, null, "access_token");
    if (response?.access_token) {
      return response.access_token;
    }
  } catch (error) {
    console.log(error, "page access token error.");
  }
};

export const getFbBusinessManagers = async (tokenFromParam: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `https://api.plai.io/fb_ig_ads/get_business_managers`,
        {
          accessToken: tokenFromParam,
        }
      );

      console.log(response, "response of fb pages");

      if (response?.data?.results?.businesses) {
        resolve(response?.data?.results?.businesses);
      } else {
        resolve([]);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const createFbBusinessManager = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `https://api.plai.io/fb_ig_ads/create_business_manager`,
        data
      );

      console.log(response, "response of create fb manager");

      if (response?.data?.success) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const createFbAsset = async (data: any) => {
  try {
    const response = await axios.post(
      `https://api.plai.io/fb_ig_ads/create_ad_account`,
      data
    );

    console.log(response, "response of create fb asset");

    return response.data.results;
  } catch (error: any) {
    console.log(error);
    return error.response.data.results;
  }
};

export const getBusinessConnectionStatus = async (data: any) => {
  try {
    const response = await axios.post(
      `https://api.plai.io/fb_ig_ads/get_business_connection_status`,
      data
    );

    console.log(response, "response of business creation status");

    if (response?.data?.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};