/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { formatMessageInPlaceholder } from "functions/global";
import "./styles.css";

const Input = (props: any) => {
  const {
    value,
    onKeyUp,
    onClick,
    loginType,
    maxLength,
    className,
    actionText,
    placeholder,
    isAutocomplete,
    tiktokInterest,
    onClickActionText,
    tiktokRedirectLink,
    inputChildClassName,
    actionTextClass,
    style,
  } = props;

  const isBorder = tiktokInterest
    ? value || isAutocomplete
    : value && isAutocomplete;

  return (
    <div
      className={`global-input ${className}`}
      style={{
        borderBottomLeftRadius: isBorder ? 0 : 5,
        borderBottomRightRadius: isBorder ? 0 : 5,
        borderBottom: isBorder ? 0 : "1px solid var(--text-input-border-color)",
        ...style,
      }}
    >
      <input
        {...props}
        value={value}
        onKeyUp={onKeyUp}
        onClick={onClick}
        maxLength={maxLength}
        placeholder={placeholder && formatMessageInPlaceholder(placeholder)}
        className={`global-input-child ${inputChildClassName}`}
      />
      {actionText &&
        (loginType === "TIKTOK" ||
        loginType === "SNAPCHAT" ||
        loginType === "LINKEDIN" ||
        loginType === "BING_ADS" ||
        loginType === "SPOTIFY" ? (
          <a href={tiktokRedirectLink}>
            <p
              className={`input-action-text ${actionTextClass}`}
              onClick={onClickActionText}
            >
              {actionText}
            </p>
          </a>
        ) : (
          <p
            className={`input-action-text ${actionTextClass}`}
            onClick={onClickActionText}
          >
            {actionText}
          </p>
        ))}
    </div>
  );
};

export default Input;
