import axios from "axios";
import store from "store";
import { addPlatformService, updatePlatformServices } from "./global";
import { ApiServerPath } from "api_server";

export const getLinkedinAccessToken = async (authCode: string) => {
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    let redirectURI =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/Ads-login"
        : "https://app.advertgenerator.com/";

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_LINKEDIN_ACCESS_TOKEN}`,
      {
        authCode,
        redirectURI,
      }
    );

    if (data?.refreshToken && data?.accessToken) {
      await addPlatformService(
        "linkedinAds",
        data?.refreshToken,
        workspaceId,
        data?.accessToken
      );

      await getLinkedinUserInfo(data?.accessToken, data?.refreshToken);

      return data;
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const getLinkedinUserInfo = async (
  accessToken: string,
  refreshToken: string
) => {
  try {
    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_LINKEDIN_PROFILE}`,
      {
        accessToken,
        refreshToken,
      }
    );

    if (data) {
      updatePlatformServices(
        "linkedinAds.userName",
        `${data?.localizedFirstName || ""} ${data?.localizedLastName || ""}`
      );
      return true;
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const getLinkedinAdAccounts = async (
  accessToken?: string,
  refreshToken?: string
) => {
  try {
    const { appReducer } = store.getState();
    const { platformServices = {} }: any = appReducer;
    const { linkedinAds = {} } = platformServices;
    let {
      refreshToken: platformRefreshToken,
      accessToken: platformAccessToken,
    } = linkedinAds;

    accessToken = accessToken || platformAccessToken;
    refreshToken = refreshToken || platformRefreshToken;

    const { data } = await axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_LINKEDIN_AD_ACCOUNTS}`,
      {
        accessToken,
        refreshToken,
      }
    );

    if (data?.elements?.length) {
      return data?.elements;
    }
  } catch (error) {
    console.log(error);
  }
};
