//  BaseUrl
export const ApiServerPath = {
  BASE_URL: "https://us-central1-plai-v3.cloudfunctions.net",

  //  Api server
  API_SERVER_BASE_URL: "https://api.plai.io",
  API_SERVER_KEY: "AIzaSyDBFCDzlke3mfCmaCBNytl_oHaNssonpmc",
  DEV_API_SERVER_BASE_URL: "https://dev-api-server-yifgeucula-uc.a.run.app",

  //  Python
  PYTHON_API_SERVER_BASE_URL:
    "https://python-api-server-yifgeucula-uc.a.run.app",

  //  Global
  SEND_ACCESS_INVITATION: "/sendAccessInvitation",
  UPDATE_PLATFORM_SERVICES: "/firebase/update_platform_service",
  ADD_PLATFORM_SERVICES: "/firebase/add_platform_service",
  CREATE_USER_WITH_TOKEN: "/authUserWithToken",

  //  Tiktok
  GET_TIKTOK_ADVERTISER_DETAILS: "/getAdvertiserDetails",
  GET_TIKTOK_ACCESS_TOKEN: "/getTikTokAccessToken",
  GET_TIKTOK_ADVERTISER_ACCOUNTS: "/tiktok_ads/get_advertiser_accounts",

  //  Google
  GET_GOOGLE_ACCOUNT_ISSUES: "/googleads/get_issues",
  GET_API_SERVER_ADWORDS_AD_ACCOUNTS: "/googleads/get_account_list",

  //  Facebook
  GET_FB_AD_ACCOUNTS: "/fb_ig_ads/get_ad_accounts",

  //  Snapchat
  GET_SNAPCHAT_REFRESH_TOKEN: "/getSnapchatRefreshToken",
  GET_SNAPCHAT_AD_ACCOUNT: "/getSnapchatAdAcc",
  GET_SNAPCHAT_USER_INFO: "/getSnapchatUserInfo",
  GET_SNAPCHAT_ORGANIZATIONS: "/snapchat_ads/get_organizations_list",

  //  Linkedin
  GET_LINKEDIN_ACCESS_TOKEN: "/linkedin_ads/get_access_token",
  GET_LINKEDIN_PROFILE: "/linkedin_ads/get_linkedin_profile",
  GET_LINKEDIN_AD_ACCOUNTS: "/linkedin_ads/get_linkedin_ad_acc",

  //  Google Analytics
  GOOGLE_ANALYTICS_ACCOUNTS: "/getAnalyticsAccounts",
  GOOGLE_ANALYTICS_PROPERTIES: "/getAnalyticsProperties",
  GOOGLE_ANALYTICS_VIEWS: "/getAnalyticsViewList",

  //  Bing Ads
  GET_BING_ADS_ACCESS_TOKEN: "/bing_ads/get_refresh_token",
  GET_BING_ADS_AD_ACCOUNTS: "/bing_ads/get_ad_accounts",

  //  Spotify Ads
  GET_SPOTIFY_ADS_ACCESS_TOKEN: "/spotify/get_access_token",
  GET_SPOTIFY_ADS_AD_ACCOUNTS: "/spotify/get_ad_accounts",

  //  Translation
  GET_TRANSLATIONS: "/utilities/get_translations",
};
