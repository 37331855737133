export const GOOGLE_ADS_SCOPES = "https://www.googleapis.com/auth/adwords";

export const YES_NO_RADIO_BTN = [
  {
    name: "No",
  },
  {
    name: "Yes",
  },
];
